<template>
    <div class="xcontainer">
        <div>
            <div class="align-items-center d-flex div-title-card justify-content-between row">
                <div class="align-items-baseline d-sm-flex flex-md-row flex-sm-column">
                    <h5 class="xtitle-buscar">Lista de transferencias a caja </h5>
                    <p class="ml-md-3 ml-sm-0 pt-md-0 pt-sm-1 xsubtitle-buscar">(Tabla principal)</p>
                </div>
                <a href="#" @click.prevent="togglePanel">
                    <i class="fa"
                       :class="{'fa-chevron-down': !showPanel, 'fa-chevron-up': showPanel}"></i></a>
            </div>

            <div :class="{collapse: !showPanel}">
                <div class="table-responsive">
                    <table :id="'list' + tableName">
                        <tr>
                            <td></td>
                        </tr>
                    </table>
                    <div :id="'pager' + tableName"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted} from "vue";
import jqgrid from "@/modules/pettyCash/config/transferJqgrid"
import useAuth from "@/modules/auth/composables/useAuth";

import usePanel from "@/composables/usePanel";
import useMainTable from "@/composables/useMainTable";
import router from "@/router";

export default {
    props: ['tableName', 'params'],
    setup(props) {
        const {companyId} = useAuth()
        const {showPanel, togglePanel} = usePanel()

        onMounted(() => {
            const body = window.$('body');
            const list = body.find("#list" + props.tableName);
            const pager = body.find("#pager" + props.tableName);

            const {onViewOptions, onChangeStatus, onStateButton} = useMainTable(props.tableName)

            onViewOptions('petty_cash/cash_transfers/options_list')
            body.off('click', '.showRow').on('click', '.showRow', function (e) {
                e.preventDefault();

                let modal = window.$('#optionsModal');
                modal.modal('hide');

                const id = window.$(this).attr("data-id");
                const transferId = window.$(this).attr("data-transferId");
                router.push({name: 'petty_cash.edit_transfer', params: {id, transferId}})
            })
            onChangeStatus('payments/status_list', '.paymentChangeStatus')
            onStateButton('payments/status_list')

            list.jqGrid({
                ...jqgrid,
                pager,
                postData: {
                    filters: props.params
                        ? [...[{field: 'companyId', value: companyId.value}], ...props.params]
                        : [{field: 'companyId', value: companyId.value}]
                }
            });
        })

        return {
            showPanel,
            togglePanel
        }
    }
}
</script>
