
import {Ref, ref} from "vue";
import {Tab} from "@/interfaces";
import TransferMainTable from "@/modules/pettyCash/components/TransferMainTable.vue"
import TransferBetweenMainTable from "@/modules/pettyCash/components/TransferBetweenMainTable.vue"
import PaymentMainTable from "@/modules/payments/components/MainTable.vue"

export default {
    props: ['id'],
    components: {
        transfers: TransferMainTable,
        TransferBetweenMainTable,
        PaymentMainTable
    },
    setup() {
        const currentTab = ref('transfers')
        const tabs: Ref<Tab[]> = ref([
            {name: 'transfers', title: 'Transferencias a caja'},
            {name: 'TransferBetweenMainTable', title: 'Transferencias entre cajas'},
            {name: 'PaymentMainTable', title: 'Pagos'},
        ])

        return {
            currentTab,
            tabs,
            isActive: (tab: Tab) => tab.name === currentTab.value,
            setTab: (tab: Tab) => currentTab.value = tab.name
        }
    }
}
