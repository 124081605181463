import {baseURL} from "@/api/erpApi";

export default {
    mtype: "GET",
    datatype: "json",
    height: "auto",
    autowidth: true,
    rowList: [10, 20, 50, 100],
    rowNum: 10,
    page: 1,
    loadtext: '<p>Cargando...</p>',
    hoverrows: false,
    refresh: true,
    multiselect: true,
    url: baseURL + '/petty_cash/cash_transfers',
    colNames: [
        "No. Transferencia",
        "Fecha",
        "Cuenta de banco",
        "Monto",
        "No. Pago",
        "Estado del pago",
        '',
        '',
    ],
    colModel: [
        {name: "code", width: 55},
        {name: "date", width: 55},
        {name: "bankAccountName", width: 40},
        {name: "amount", width: 40},
        {name: "paymentCode", width: 40},
        {name: "paymentStatusLabel", width: 40, align: 'center'},
        {name: "options", width: 55, align: 'center'},
        {name: "hiddenOptions", hidden: true}
    ],
    sortname: 'createdAt',
    sortorder: "desc",
    viewrecords: true,
    gridview: true
};
