
import GeneralData from '../components/GeneralData.vue'
import usePettyCash from "@/modules/pettyCash/composables/usePettyCash";
import toastr from "toastr";
import {onMounted, ref} from "vue";
import router from "@/router";
import Tabs from "@/modules/pettyCash/components/Tabs.vue";

export default {
    components: {Tabs, GeneralData},
    props: ['id'],
    setup(props: { id: string }) {
        const {clearForm, pettyCashForm, updatePettyCash, catalogs, getPettyCash} = usePettyCash()
        const sending = ref(false)
        const loading = ref(true)

        clearForm();

        onMounted(async () => {
            pettyCashForm.value = await getPettyCash(props.id)
            loading.value = false

            // console.log(window.$, 'ddd');
            // const body = window.$('body');
            // body.off('click', '.transferTo, #transferTo').on('click', '.transferTo, #transferTo', function (e) {
            //     e.preventDefault();
            //     console.log('eee')
            //
            //     router.push({name: 'petty_cash.create_transfer', params: {id: props.id}})
            // })
        })

        return {
            mode: 'EDIT',
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'petty_cash'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updatePettyCash(pettyCashForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'petty_cash'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
