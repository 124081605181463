import {baseURL} from "@/api/erpApi";

export default {
    mtype: "GET",
    datatype: "json",
    height: "auto",
    autowidth: true,
    rowList: [10, 20, 50, 100],
    rowNum: 10,
    page: 1,
    loadtext: '<p>Cargando...</p>',
    hoverrows: false,
    refresh: true,
    multiselect: true,
    url: baseURL + '/transfers_between',
    colNames: [
        "No. Transferencia",
        "Fecha",
        "Caja de origen",
        "Caja de destino",
        "Monto",
        '',
        '',
    ],
    colModel: [
        {name: "code", width: 55},
        {name: "date", width: 55},
        {name: "pettyCashName", width: 40},
        {name: "pettyCashDestinyName", width: 40},
        {name: "amount", width: 40},
        {name: "options", width: 55, align: 'center', hidden: true},
        {name: "hiddenOptions", hidden: true}
    ],
    sortname: 'code',
    sortorder: "desc",
    viewrecords: true,
    gridview: true
};
